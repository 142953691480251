var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button-group",
    { staticClass: "mb-2", attrs: { vertical: "" } },
    _vm._l(_vm.maps, function(map) {
      return _c(
        "b-button",
        {
          key: map.id,
          class:
            (_vm.$route.params.map_id === map.id ? "active" : "") +
            " " +
            (!_vm.availableMapIds.includes(map.id) ? "no-data" : ""),
          attrs: { variant: "outline-primary" },
          on: {
            click: function($event) {
              return _vm.$emit("select_map", map.id)
            }
          }
        },
        [_vm._v(" " + _vm._s(map.name) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }