var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass:
        "d-flex flex-column justify-content-center align-items-center mb-2",
      style: {
        "background-image": "url(" + _vm.selected_map_url + ")",
        "border-width": _vm.$route.params.map_id ? "0" : ".5rem"
      }
    },
    [
      _c("img", {
        staticClass: "team-logo",
        attrs: { src: _vm.image, alt: _vm.team_name },
        on: { error: _vm.set_default_image }
      }),
      _vm.player_name
        ? _c("h5", [_vm._v(" " + _vm._s(_vm.player_name) + " ")])
        : _c("h5", [_vm._v(" " + _vm._s(_vm.team_name) + " ")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }